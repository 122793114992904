import React, { FC } from 'react';
import { ThemeState, getThemeState } from '../store/actions/theme';
import { connect } from 'react-redux';
import { MainReducerState } from '../store/reducers';

const hexToRgba = (hex: string, opacity: number = 1) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? 'rgba(' + parseInt(result[1], 16) + ',' + parseInt(result[2], 16) + ',' + parseInt(result[3], 16) + ',' + opacity + ')' : null;
};

interface ThemeProps {
    themeState: ThemeState;
}

const Theme: FC<ThemeProps> = ({themeState}) => (
    <style
        dangerouslySetInnerHTML={{__html: `
            ::selection {
                background: ` + themeState.color + `;
            }

            h1,
            .text-primary,
            #ticket-layout .card .value
            {
                color: ` + themeState.color + `;
            }

            .checkbox-row .check,
            .input-row input:focus
            {
                border-color: ` + themeState.color + `;
            }

            .btn,
            .bg-primary,
            .PaylineWidget.pl-container-default .pl-pay-btn,
            .checkbox-row input:checked + .check
            {
                background-color: ` + themeState.color + `;
            }

            .PaylineWidget.pl-container-default .pl-pay-btn:focus,
            .btn:focus
            {
                background-color: ` + hexToRgba(themeState.color, 0.3) + `;
            }

            .input-row input:focus,
            .PaylineWidget.pl-container-default .pl-pay-btn:focus,
            .btn:focus
            {
                box-shadow: 0 0 0 2px ` + hexToRgba(themeState.color, 0.2) + `;
            }

            #payment-layout .payment-result svg
            {
                fill: ` + themeState.color + `;
            }
        `}}
    />
);

const mapStateToProps = (state: MainReducerState) => ({
    themeState: getThemeState(state),
});

export default connect(
    mapStateToProps,
    {
    },
)(Theme);
