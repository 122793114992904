import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'home.title',
        defaultMessage: 'Saisissez votre plaque d’immatriculation ou le N° du ticket :',
        description: 'Home title',
    },
    inputPlaceholder: {
        id: 'home.input.placeholder',
        defaultMessage: 'Immatriculation ou n° ticket',
        description: 'Home input placeholder',
    },
    inputErrorTitle: {
        id: 'home.input.errorTitle',
        defaultMessage: 'Veuillez réessayer',
        description: 'Home input error title',
    },
    inputErrorMessage: {
        id: 'home.input.errorMessage',
        defaultMessage: 'Nous n’avons pas trouvé de correspondance pour cette plaque d’immatriculation. Réessayez ou saisissez le numéro de ticket.',
        description: 'Home input error message',
    },
    inputErrorPlate: {
        id: 'home.input.errorPlate',
        defaultMessage: 'Nous n’avons pas trouvé de correspondance pour cette plaque d’immatriculation. Réessayez ou saisissez le numéro de ticket.',
        description: 'Home input error message',
    },
    inputErrorTicket: {
        id: 'home.input.errorTicketNumber ',
        defaultMessage: 'Nous n’avons pas trouvé de correspondance pour ce numéro de titre. Réessayez ou saisissez votre plaque d\'immatriculation.',
        description: 'Home input error message',
    },
});
