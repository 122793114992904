import React, { FC } from 'react';

import '../assets/styles/Error.less';

interface ErrorMessageProps {
    title?: string;
}

const ErrorMessage: FC<ErrorMessageProps> = ({
    children, title,
}) => (
    <div className="card error-message">
        {title && (
            <div className="title">{title}</div>
        )}
        <div className="content">
            {children}
        </div>
    </div>
);

export default ErrorMessage;
