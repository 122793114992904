import React, { FC } from 'react';
import Loader from './Loader';

import '../assets/styles/Button.less';

export type ButtonProps = Omit<React.ButtonHTMLAttributes<any>, 'type'> & {
    type?: string;
    loading?: boolean;
};

const Button: FC<ButtonProps> = ({
    children, type, loading, ...rest
}) => (
    <button className={'btn ' + type + ' ' + (loading ? 'loading' : '')} {...rest}>
        <Loader loading={loading} />
        {!loading && (
            <span className="btn-label">{children}</span>
        )}
    </button>
);

Button.defaultProps = {
    type: 'primary',
    loading: false,
};

export default Button;
