import { combineReducers } from 'redux';

import { AuthState, authReducer } from '../actions/auth';
import { CookiesState, cookiesReducer } from '../actions/cookies';
import { TestState, testReducer } from '../actions/test';
import { ThemeState, themeReducer } from './../actions/theme';
import { TransactionsState, transactionsReducer } from '../actions/transactions';

export interface MainReducerState {
    readonly auth: AuthState;
    readonly cookies: CookiesState;
    readonly test: TestState;
    readonly theme: ThemeState;
    readonly transactions: TransactionsState;
}

const mainReducer = combineReducers<MainReducerState>({
    auth: authReducer,
    cookies: cookiesReducer,
    test: testReducer,
    theme: themeReducer,
    transactions: transactionsReducer,
});

export default mainReducer;

export interface RequestState<T = any> {
    readonly data: T;
    readonly error?: any;
    readonly loading: boolean;
    readonly response?: any;
    readonly success?: boolean;
    readonly total?: number;
    readonly payload?: any;
}
