import React, { FC } from 'react';

import '../assets/styles/Loader.less';

interface LoaderProps {
    loading?: boolean;
}

const Loader: FC<LoaderProps> = ({
    loading,
}) => loading ? (<div className="loader"><span/></div>) : null;

Loader.defaultProps = {
    loading: false,
};

export default Loader;
