// import api from './_client';

export interface LoginPayload {
    username: string;
    password: string;
}

export const login = (payload: LoginPayload) => // api.post('/auth', payload);
    new Promise((resolve, reject) => { // FAKE API
        setTimeout(() => {
            if (payload.username !== 'jean-mi@ezeeworld.com') {
                reject({ status: 404 });
            } else {
                if (payload.password === 'lele') {
                    document.cookie = 'isLogged=1';
                    resolve({
                        user: {
                            id: 1337,
                            firstName: 'Jean-Michel',
                            lastName: 'Bécatresse',
                            email: 'jean-mi@ezeeworld.com',
                        },
                    });
                } else {
                    reject({ status: 403 });
                }
            }
        }, Math.floor(1000 + Math.random() * 500));
    });

export const logout = () => // api.post('/auth/logout');
    new Promise((resolve) => { // FAKE API
        setTimeout(() => {
            document.cookie = 'isLogged= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
            resolve();
        }, Math.floor(1000 + Math.random() * 500));
    });

export const checkLoginStatus = () => // api.get('/me');
    new Promise((resolve, reject) => { // FAKE API
        setTimeout(() => {
            if (document.cookie.split(';').find((cookie) => cookie.includes('isLogged') && cookie.split('=')[1] === '1')) {
                resolve({
                    id: 1337,
                    firstName: 'Jean-Michel',
                    lastName: 'Bécatresse',
                    email: 'jean-mi@ezeeworld.com',
                });
            } else {
                reject();
            }
        }, Math.floor(1000 + Math.random() * 500));
    });
