import { Site, Transaction } from './apiTypes';
import { SearchPaginationQuery } from './';
import api from './_client';
import { getRoute, RoutePathName, getDomain } from '../../routes';

export interface TransactionDetailsPayload {
    id: string;
}

export interface TransactionInitPayload {
    siteId: string;
    titleStripe: string;
    hash: string;
}

export interface TransactionThemePayload {
    siteId: string;
}

export type TransactionListPayload = SearchPaginationQuery;

export interface TransactionAuthNmiCompletedPayload {
    siteId: Site['id'];
    body: Transaction;
}

export interface TransactionAuthNmiFailurePayload {
    siteId: Site['id'];
    body: Transaction;
}

export interface TransactionAuthNmiErrorPayload {
    siteId: Site['id'];
    body: Transaction;
}

export const init = (payload: TransactionInitPayload) => {
    return api.get(`/transactions/init/${payload.siteId}/${payload.titleStripe}${payload.hash ? ('/' + payload.hash) : ''}`);
};

export const theme = (payload: TransactionThemePayload) => {
    return api.get(`/sites/appearance/${payload.siteId}`);
};

export const details = (payload: TransactionDetailsPayload) => api.get(`/transactions/${payload.id}`);

export const start = (payload: Transaction) => {
    const id: string = payload.id;
    delete payload.id;

    return api.put(`/transactions/start/${id}`, {
        ...payload,
        returnURL: encodeURI(getDomain() + getRoute(RoutePathName.paymentCheck)),
        cancelURL: encodeURI(getDomain() + getRoute(RoutePathName.paymentError)),
    });
};

export const invoice = (payload: Transaction) => {
    return api.get(`/transactions/receipt/${payload.id}`, {responseType: 'blob'});
};

export const authNmiCompleted = async (payload: TransactionAuthNmiCompletedPayload) => {
    return api.post(`/transactions/nmicompleted/${payload?.siteId ?? ''}`, payload.body);
};

export const authNmiFailure = async (payload: TransactionAuthNmiFailurePayload) => {
    return api.post(`/transactions/nmifailure/${payload?.siteId ?? ''}`, payload.body);
};

export const authNmiError = async (payload: TransactionAuthNmiErrorPayload) => {
    return api.post(`/transactions/nmierror/${payload?.siteId ?? ''}`, payload.body);
};
