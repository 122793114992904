import React, { createContext, useState, FC } from 'react';

interface LayoutContextTypes {
    hideHeader: boolean;
    setHideHeader: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LayoutContext = createContext<LayoutContextTypes>({
    hideHeader: false,
    setHideHeader: () => undefined,
});

export const LayoutContextProvider: FC = ({ children }) => {
    const [hideHeader, setHideHeader] = useState(true);

    const layoutContext = {
        hideHeader,
        setHideHeader,
    };

    return (
        <LayoutContext.Provider value={layoutContext}>
            {children}
        </LayoutContext.Provider>
    );
};
