import React, { FC, useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { MainReducerState } from '../../store/reducers';
import Seo from '../../components/Seo';
import Button from '../../components/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import GenericMessages from '../../locale/Generic.messages';
import ErrorMessage from '../../components/ErrorMessage';
import { useHistory } from 'react-router-dom';
import { getRoute, RoutePathName } from '../../routes';
import { LayoutContext } from '../../context/LayoutContext';
import {
    TransactionsState,
    init as transactionsInit,
} from '../../store/actions/transactions';
import useLocalStorage from '../../hooks/localStorage';
import { TicketSearchStatus } from '../../store/api/apiTypes';

interface TicketProps {
    transactions: TransactionsState;
    init: typeof transactionsInit.trigger;
    initReset: typeof transactionsInit.reset;
}

const Ticket: FC<TicketProps> = ({
    transactions,
    init,
    initReset,
}) => {
    const { formatMessage } = useIntl();
    const history = useHistory();
    const [userInput, setUserInput] = useLocalStorage('titleId', '');
    const [userInputError, setUserInputError] = useState<TicketSearchStatus>();
    const [isAutoInit, setIsAutoInit] = useState<boolean>(false);
    const { setHideHeader } = useContext(LayoutContext);
    const [siteId, setSiteId] = useLocalStorage('currentSiteId', undefined);
    const [, setTicketCode] = useLocalStorage('currentTicketCode', undefined);
    const [, setSecurityHash] = useLocalStorage('currentSecurityHash', undefined);
    const [, setTransaction] = useLocalStorage('currentTransaction', undefined);

    // ---------------------------------------
    // Query params

    const urlParams = new URLSearchParams(window.location.search);
    const siteQueryParam = urlParams.get('s');
    const autoInitQueryParam = urlParams.get('autoInit');

    // ---------------------------------------
    // On page init

    useEffect(() => {
        setTicketCode(undefined);
        setSecurityHash(undefined);
        setHideHeader(false);

        if (!siteId) {
            if (siteQueryParam) {
                setSiteId(siteQueryParam);
            } else {
                history.push(getRoute(RoutePathName.home));
            }
        }

        if (autoInitQueryParam) {
            setIsAutoInit(true);
            initReset();
            init({
                siteId: siteId || siteQueryParam,
                titleStripe: autoInitQueryParam,
            });
        }

    }, [setHideHeader]); // eslint-disable-line react-hooks/exhaustive-deps

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserInput(e.target.value);
        setUserInputError(undefined);
    };

    // ---------------------------------------
    // Submit the ticket number

    const next = () => {
        init({
            siteId,
            titleStripe: userInput,
        });
    };

    // ---------------------------------------
    // Transaction status

    useEffect(() => {
        initReset();

        if (transactions.init.success) {
            setTransaction(transactions.init.data);
            history.push(getRoute(RoutePathName.transaction));
        }

        if (transactions.init.error) {
            setIsAutoInit(false);
            setUserInputError(TicketSearchStatus.ticketNotFound);
        }
    }, [transactions.init.success, transactions.init.error]); // eslint-disable-line react-hooks/exhaustive-deps

    // ---------------------------------------
    // Error

    const inputIsTicket = () => {
        return userInput.match(/\d{6,8}/);
    };

    const getErrorMessage = () => {
        return formatMessage(inputIsTicket() ? messages.inputErrorTicket : messages.inputErrorPlate);
    };

    // ---------------------------------------
    // Render page

    return !isAutoInit ? (
        <div id="home-layout">
            <Seo title={formatMessage(messages.title)} />
            <h1>
                <FormattedMessage {...messages.title}/>
            </h1>

            <div className="input-row">
                <input
                    type="text"
                    placeholder={formatMessage(messages.inputPlaceholder)}
                    onChange={onInputChange}
                    autoCapitalize="none"
                />
            </div>

            {userInputError && (
                <ErrorMessage title={formatMessage(messages.inputErrorTitle)}>
                    {getErrorMessage()}
                </ErrorMessage>
            )}

            <Button onClick={next} loading={transactions.init.loading}>
                <FormattedMessage {...GenericMessages.next}/>
            </Button>
        </div>
    ) : <></>;

};

const mapStateToProps = (state: MainReducerState) => ({
    transactions: state.transactions,
});

export default connect(
    mapStateToProps,
    {
        init: transactionsInit.trigger,
        initReset: transactionsInit.reset,
    },
)(Ticket);
