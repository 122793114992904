// Types of the backend API

type ObjectId = string;

export interface Organization {
    id: string;
    name: string;
}

export interface User {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    role?: Role;
    site: Site;
    permissions?: {
        [key in Permission]?: PermissionRight;
    };
    mergedPermissions?: {
        [key in Permission]?: PermissionRight;
    };
    scope?: {
        [key in RoleScopes]?: any[];
    };
}

export interface Scope {
    agencies: ObjectId[];
}

export interface Role {
    id: ObjectId;
    name: string;
    removable?: boolean;
    permissions?: {
        [key in Permission]?: PermissionRight;
    };
    scope?: {
        [key in RoleScopes]?: boolean;
    };
}

export interface Transaction {
    id: string;
    paymentStatus: Status;
    ticketId: string;
    titleId: string;
    totalDueAmount: number;
    updatedAt: Date;
    exitDateTime: Date;
    entryDateTime: Date;
    currencyIso: string;
    currencyCentsDigits: number;
    createdAt: Date;
    alreadyPaidCharge: number;
    transactionId: string;
    site: Site;
    paymentToken: string;
    zoneName: string;
    customerEmail: string;
}

export interface Site {
    id: string;
    name: string;
    phone?: string;
    apimKey?: string;
    services?: ServiceType[];
    siteCode?: number;
    authentications?: Authentication[];
    siteId?: string;
    currencyIso?: string;
    currencyCentsDigits?: number;
    deviceId?: number;
    parcId?: number;
    deviceName?: string;
    createdAt?: Date;
    updatedAt?: Date;
    enabled: boolean;
    themeColor?: string;
    logoImage?: string;
    bannerImage?: string;
    loginImage?: string;
    nbPlates?: number;
    deadline?: boolean;
    badgeType?: string;
    psp?: Psp;
}

export interface Authentication {
    type: ServiceType;
    login: string;
    password: string;
}

export interface Psp {
    name: PSPName;
    payline_contractNumber?: string;
    nmi_security_key?: string;
}

export enum ServiceType {
    payExt = 'PayExt',
    ppo = 'Ppo',
}

export interface AuthResponse {
    user: User;
    authToken: string;
    refreshToken: string;
}

export interface ListResponse<T> {
    items: T[];
    totalCount: number;
    page: number;
    pageSize: number;
    pageCount: number;
}

// ------------------------------------------
// ENUMs

export enum PermissionRight {
    read        = 'r',
    write       = 'w',
    disabled    = 'disabled',
}

export enum Permission {
    SuperAdmin = 'superAdmin',
    Roles = 'roles',
}

export enum RoleScopes {
    User = 'user',
}

export enum Status {
    Pending = 'pending',
    Cancelled = 'cancelled',
    Paid = 'paid',
    Abandonned = 'abandonned',
}

export enum TicketSearchStatus {
    ok = 'Ok',
    nothingToPay = 'NothingToPay',
    underParkingDeductible = 'UnderParkingDeductible',
    ticketNotFound = 'TicketNotFound',
    ticketOutOfPark = 'TicketOutOfPark',
    priceCalcFailure = 'PriceCalcFailure',
    titleNotAllowed = 'TitleNotAllowed',
    messagingDown = 'MessagingDown',
    payExtInactiveOnZone = 'PayExtInactiveOnZone',
    noShiftOpen = 'NoShiftOpen',
}

export enum PSPName {
    payline = 'payline',
    nmi = 'nmi',
    advam = 'advam',
    monerise = 'monerise',
}
