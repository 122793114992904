import { defineMessages } from 'react-intl';

export default defineMessages({
    errorNotAvailable: {
        id: 'home.errorNotAvailable',
        defaultMessage: 'Paiement momentanément indisponible sur ce parking',
    },
    ticketNotFound: {
        id: 'home.ticketNotFound',
        defaultMessage: 'Le titre n\'a pas été trouvé',
    },
    ticketOutOfParking: {
        id: 'home.ticketOutOfParking',
        defaultMessage: 'Le titre est déjà sorti',
    },
    titleNotAllowed: {
        id: 'home.titleNotAllowed',
        defaultMessage: 'Ce titre ne peut pas être payé ici',
    },
});
