import React, { FC, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';

import { MainReducerState } from '../../store/reducers';
import { getCookiesState, cookies, CookiesState, CookieNames } from '../../store/actions/cookies';

import { acceptAllCookies, refuseAllCookies, removeGoogleAnalytics, addGoogleAnalytics, setCookiesOptions } from '../../helpers/cookies';
import Seo from '../../components/Seo';
import Button from '../../components/Button';

// tslint:disable: max-line-length

interface PrivacyProps {
    accept: typeof cookies.actions.acceptAll;
    cookiesState: CookiesState;
    refuse: typeof cookies.actions.refuse;
    toggle: typeof cookies.actions.toggle;
}

const Privacy: FC<PrivacyProps> = ({
    accept, cookiesState, refuse, toggle,
}) => {
    const [_, setCookie, removeCookie] = useCookies(); // eslint-disable-line @typescript-eslint/no-unused-vars
    const [showSuccess, setShowSuccess] = useState(false);
    const location = useLocation();
    const onToggle = (cookie: string) => {
        toggle(cookie);

        if (cookie === CookieNames.GA) {
            if (cookiesState.ga) {
                removeGoogleAnalytics(removeCookie);
                setCookie(CookieNames.GA, 0, setCookiesOptions);
            } else {
                addGoogleAnalytics(location.pathname);
                setCookie(CookieNames.GA, 1, setCookiesOptions);
            }
        }
    };
    const onClickAccept = useCallback(() => {
        acceptAllCookies(setCookie, location.pathname);
        accept();
        setShowSuccess(true);

        const timeout = window.setTimeout(() => {
            setShowSuccess(false);
        }, 1000);

        return () => window.clearTimeout(timeout);
    }, [accept, location.pathname, setCookie]);
    const onClickRefuse = useCallback(() => {
        refuseAllCookies(setCookie, removeCookie);
        refuse();
        setShowSuccess(true);

        const timeout = window.setTimeout(() => {
            setShowSuccess(false);
        }, 1000);

        return () => window.clearTimeout(timeout);
    }, [refuse, removeCookie, setCookie]);

    return (
        <div className="container page">
            <Seo title="Politique de confidentialité et Protection de la vie privée" />
            <h1>
                Politique de confidentialité et Protection de la vie privée
            </h1>
            <h2 id="cookies">
                Choix des cookies
            </h2>

            <h3>
                Optionnels
            </h3>
            <ul>
                <li className="flex">
                    <input type="checkbox" checked={!!cookiesState.ga} onChange={onToggle.bind(null, 'ga')} />
                    <p>
                        Cookies de Google Analytics destinés à réaliser des statistiques de navigation et de fréquentation
                        <code>ezee-ga, _gat, _gid, _ga</code>
                    </p>
                </li>
            </ul>
            <h3>
                Obligatoires
            </h3>
            <ul>
                <li className="flex">
                    <input type="checkbox" checked disabled />
                    <p>
                        Cookie permettant de déterminer si l'utilisateur a accepté ou refusé un ou plusieurs cookies
                        <code>ezee-has_made_choice</code>
                    </p>
                </li>
            </ul>
            <div className="flex">
                <Button
                    type="ghost"
                    onClick={onClickRefuse}
                >
                    Tout refuser
                </Button>
                <Button
                    type="primary"
                    onClick={onClickAccept}
                >
                    Tout accepter
                </Button>
                {showSuccess && (
                    <div>Enregistré</div>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    cookiesState: getCookiesState(state),
});

export default connect(
    mapStateToProps,
    {
        accept: cookies.actions.acceptAll,
        refuse: cookies.actions.refuse,
        toggle: cookies.actions.toggle,
    },
)(Privacy);
