import React, { FC, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { MainReducerState } from '../../store/reducers';
import Seo from '../../components/Seo';
import Button from '../../components/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import Price from '../../components/Price';
import moment from 'moment';
import { LayoutContext } from '../../context/LayoutContext';
import { getThemeState, ThemeState } from '../../store/actions/theme';
import {ReactComponent as IconConfirm} from '../../assets/images/icon-confirm.svg';
import useLocalStorage from '../../hooks/localStorage';
import { Transaction } from '../../store/api/apiTypes';
import { useHistory } from 'react-router-dom';
import { getRoute, RoutePathName } from '../../routes';
import {
    TransactionsState,
    invoice as transactionsInvoice,
} from '../../store/actions/transactions';
import { downloadFile } from '../../hooks';

interface PaymentSuccessProps {
    themeState: ThemeState;
    transactions: TransactionsState;
    getInvoice: typeof transactionsInvoice.trigger;
    getInvoiceReset: typeof transactionsInvoice.reset;
}

const PaymentSuccess: FC<PaymentSuccessProps> = ({
    themeState,
    transactions,
    getInvoice,
    getInvoiceReset,
}) => {
    const { formatMessage } = useIntl();
    const { setHideHeader } = useContext(LayoutContext);
    const history = useHistory();
    const [transaction] = useLocalStorage('currentTransaction', undefined);

    const currentTransaction: Transaction = (transaction && transaction !== undefined) ? transaction : undefined;

    useEffect(() => {
        setHideHeader(false);
    }, [setHideHeader]);

    useEffect(() => {
        if (!currentTransaction) {
            history.push(getRoute(RoutePathName.home));
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // ---------------------------------------
    // Invoices

    const invoice = () => {
        if (currentTransaction) {
            getInvoice({id: currentTransaction.id});
        }
    };

    useEffect(() => {
        if (transactions && transactions.invoice.success && transactions.invoice.data) {
            const fileName = `receipt_${transactions.invoice.payload.id}.pdf`;
            downloadFile(fileName, transactions.invoice.data, 'application/pdf');
            getInvoiceReset();
        }
    }, [transactions.invoice.success]); // eslint-disable-line react-hooks/exhaustive-deps

    return currentTransaction && (
        <div id="payment-layout">
            <Seo title={formatMessage(messages.pageTitle)} />
            <h1>{themeState.name + (currentTransaction.zoneName ? (' - ' + currentTransaction.zoneName) : '')}</h1>

            <hr />

            <div className="payment-result confirm">
                <IconConfirm />
                <div className="text-primary">
                    <FormattedMessage {...messages.paymentConfirmed} />
                </div>
            </div>

            <p>
                <FormattedMessage
                    {...messages.intro}
                    values={{
                        price: <strong><Price value={currentTransaction.totalDueAmount} currency={currentTransaction.currencyIso} currencyCentsDigits={currentTransaction.currencyCentsDigits} /></strong>,
                    }}
                />
            </p>

            <Button onClick={invoice}>
                <FormattedMessage {...messages.ctaInvoice}/>
            </Button>
            {currentTransaction.customerEmail && (
                <em className="invoice-email">
                    <FormattedMessage
                        {...messages.invoiceEmail}
                        values={{
                            email: currentTransaction.customerEmail,
                        }}
                    />
                </em>
            )}

            <p>
                <FormattedMessage
                    {...messages.outro1}
                    values={{
                        time: <strong>{moment(currentTransaction.exitDateTime).format('HH[h]mm')}</strong>,
                    }}
                />
            </p>

            <p>
                <FormattedMessage {...messages.outro2} />
            </p>

        </div>
    );

};

const mapStateToProps = (state: MainReducerState) => ({
    themeState: getThemeState(state),
    transactions: state.transactions,
});

export default connect(
    mapStateToProps,
    {
        getInvoice: transactionsInvoice.trigger,
        getInvoiceReset: transactionsInvoice.reset,
    },
)(PaymentSuccess);
