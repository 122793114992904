import React, { FC, useState, useContext, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { MainReducerState } from '../../store/reducers';
import Seo from '../../components/Seo';
import Button from '../../components/Button';
import { FormattedMessage, useIntl, FormattedDate, FormattedTime } from 'react-intl';
import messages from './messages';
import moment from 'moment';
import Price from '../../components/Price';
import { validateEmail } from '../../helpers';
import { useHistory } from 'react-router-dom';
import { getRoute, RoutePathName } from '../../routes';
import { LayoutContext } from '../../context/LayoutContext';
import useLocalStorage from '../../hooks/localStorage';
import { PSPName, Status, Transaction } from '../../store/api/apiTypes';
import { getThemeState, ThemeState } from '../../store/actions/theme';
import {
    TransactionsState,
    start as transactionsStart,
    authNmiFailure as authNmiFailureAction,
    authNmiCompleted as authNmiCompletedAction,
    authNmiError as authNmiErrorAction,
    getAuthNmiCompletedState,
} from '../../store/actions/transactions';

import '../../assets/styles/Transaction.less';
import ErrorMessage from '../../components/ErrorMessage';
import GenericMessages from '../../locale/Generic.messages';
import paymentMessages from '../../locale/payment.messages';
import { usePrevious } from '../../hooks';
import Modal3DSecure from '../payment/Modal3DSecure';

interface TransactionProps {
    themeState: ThemeState;
    transactions: TransactionsState;
    start: typeof transactionsStart.trigger;
    startReset: typeof transactionsStart.reset;
    authNmiCompleted: typeof authNmiCompletedAction.trigger;
    authNmiFailure: typeof authNmiFailureAction.trigger;
    authNmiError: typeof authNmiErrorAction.trigger;
}

declare global {
    interface Window {
        CollectJS?: any;
        Gateway?: any;
    }
}

let threeDSecureInterface: any;

const TransactionPage: FC<TransactionProps> = ({
    themeState,
    transactions,
    start,
    startReset,
    authNmiCompleted,
    authNmiFailure,
    authNmiError,
}) => {
    const { formatMessage } = useIntl();
    const history = useHistory();
    const [userWantInvoice, setUserWantInvoice] = useState<boolean>(false);
    const [userEmail, setUserEmail] = useState<string>('');
    const [userEmailError, setUserEmailError] = useState<boolean>();
    const authNmiState = useSelector(getAuthNmiCompletedState);
    const [startError, setStartError] = useState<boolean>();
    const { setHideHeader } = useContext(LayoutContext);
    const [isWaitingForPayment, setIsWaitingForPayment] = useState(false);
    const [error, setError] = useState<any>();
    const [modal3DSecureVisible, setModal3DSecureVisible] = useState<boolean>(false);
    const [transaction, setTransaction] = useLocalStorage('currentTransaction', undefined);
    const currentTransaction: Transaction = (transaction && transaction !== undefined) ? transaction : undefined;
    const previous = usePrevious({
        authNmiState,
    });
    const close3DSecureModal = () => {
        threeDSecureInterface?.unmount();
        setIsWaitingForPayment(false);
        setModal3DSecureVisible(false);
    };

    useEffect(() => {
        setHideHeader(false);
    }, [setHideHeader]);

    // ---------------------------------------
    // On page init

    useEffect(() => {
        if (!currentTransaction) {
            history.push(getRoute(RoutePathName.home));
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // ---------------------------------------
    // Invoice field

    const onInvoiceCheckChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserWantInvoice(e.target.checked);
    };

    const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserEmail(e.target.value);
    };

    const checkEmail = () => {
        if (userEmail) {
            setUserEmailError(!validateEmail(userEmail));
        } else {
            setUserEmailError(false);
        }
    };

    // ---------------------------------------
    // Start user email

    useEffect(() => {
        if (transactions.start.success && transactions.start.data) {
            setTransaction(transactions.start.data);
            if (currentTransaction.site.psp?.name === PSPName.payline) {
                history.push(getRoute(RoutePathName.payment));
            } else if (currentTransaction.site.psp?.name === PSPName.nmi) {
                window.CollectJS.startPaymentRequest();
            }
        }

        if (transactions.start.error) {
            setStartError(true);
            startReset();
        }
    }, [transactions.start.success, transactions.start.error]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (transaction && transaction.totalDueAmount && transaction.site.psp?.name === PSPName.nmi) {
            window.CollectJS.configure({
                variant: 'lightbox',
                instructionText: formatMessage(paymentMessages.instructionPaymentNMI),
                buttonText: formatMessage(paymentMessages.buttonPaymentNMI),
                callback: (e: any) => {
                    setIsWaitingForPayment(true);
                    const options = {
                        paymentToken: e.token,
                        currency: transaction.site?.currencyIso,
                        amount: transaction.totalDueAmount / Math.pow(10, 2),
                        email: 'unknown@unknown.com',
                        city: 'transaction.site?.siteCode',
                        address1: transaction.id,
                        country: 'GB',
                        firstName: 'unknown',
                        lastName: 'unknown',
                        postalCode: 'PayExt',
                    };
                    threeDSecureInterface?.unmount();
                    const gateway = window.Gateway.create('checkout_public_S958hSd7QtWW9fB3By3fEgdj3q6Qx337');
                    const threeDS = gateway.get3DSecure();
                    threeDSecureInterface = threeDS.createUI(options);
                    threeDSecureInterface.start('#threeDSMountPoint');
                    gateway.on('error', (gatewayError: any) => {
                        console.error('gateway', gatewayError);
                        authNmiError({
                            siteId: transaction.site?.siteId,
                            gatewayError,
                        });
                        setError(GenericMessages.error);
                        close3DSecureModal();
                    });
                    threeDS.on('error', (threeDSError: any) => {
                        console.error('threeDS', threeDSError);
                        authNmiError({
                            siteId: transaction.site.siteId,
                            threeDSError,
                        });
                        setError(GenericMessages.error);
                        close3DSecureModal();
                    });
                    threeDSecureInterface.on('complete', (res: any) => {
                        console.log('complete', res);
                        const body = {
                            card: e.card,
                            ...res,
                            xid: res.xid,
                            cavv: res.cavv,
                            eci: res.eci,
                            cardHolderAuth: res.cardHolderAuth,
                            directoryServerId: res.directoryServerId,
                            threeDsVersion: res.threeDsVersion,
                            paymentToken: options.paymentToken,
                            currency: options.currency,
                            amount: transaction.totalDueAmount,
                            transactionId: transaction?.id,
                        };
                        authNmiCompleted({
                            siteId: transaction.site.siteId,
                            body,
                        });
                        close3DSecureModal();
                    });

                    threeDSecureInterface.on('failure', (res: any) => {
                        console.log('failure', res);
                        if (res.code === 'TRANSACTION_STATUS_N') {
                            setError(paymentMessages.failure_paymentNMI_N);
                        } else if (res.code === 'TRANSACTION_STATUS_U') {
                            setError(paymentMessages.failure_paymentNMI_U);
                        } else if (res.code === 'TRANSACTION_STATUS_R') {
                            setError(paymentMessages.failure_paymentNMI_R);
                        } else {
                            setError(paymentMessages.failure_paymentNMI);
                        }
                        const body = {
                            ...res,
                            transactionId: transaction?.id,
                        };
                        authNmiFailure({
                            siteId: transaction.site.siteId,
                            body,
                        });
                        close3DSecureModal();
                    });
                    threeDSecureInterface.on('error', (res: any) => {
                        console.error('error', res);
                        const body = {
                            ...res,
                            transactionId: transaction?.id,
                        };
                        setError(GenericMessages.error);
                        authNmiError({
                            siteId: transaction.site.siteId,
                            body,
                        });
                        close3DSecureModal();
                    });
                    threeDSecureInterface.on('challenge', (res: any) => {
                        console.log('challenge', res);
                        setModal3DSecureVisible(true);
                    });
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transaction]);

    useEffect(() => {
        return () => {
            threeDSecureInterface?.unmount();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (previous?.authNmiState.loading && !authNmiState.loading) {
            if (authNmiState.success && authNmiState.data) {
                setTransaction(authNmiState.data);
                if (authNmiState.data.paymentStatus === Status.Paid) {
                    history.push(getRoute(RoutePathName.paymentSuccess));
                } else {
                    history.push(getRoute(RoutePathName.paymentError));
                }
            }
        }
    }, [previous, authNmiState, history, setTransaction]);
    // ---------------------------------------
    // Validate

    const next = () => {

        setStartError(false);
        setError(undefined);
        if (transaction.psp === PSPName.nmi && transaction.paymentStatus === Status.Pending) {
            window.CollectJS.startPaymentRequest();
        } else {
            const startPayload: any = {
                id: transaction.id,
                psp: transaction.site.psp?.name,
            };
            if (userEmail) {
                if (validateEmail(userEmail)) {
                    startPayload.customerEmail = userEmail;
                    start(startPayload);
                }
            } else {
                start(startPayload);
            }
        }
    };

    // ---------------------------------------
    // Render page

    return (
        <div id="ticket-layout">
            {currentTransaction && (
                <>
                    <Seo title={formatMessage(messages.pageTitle)} />
                    <h1>{themeState.name + (currentTransaction.zoneName ? (' - ' + currentTransaction.zoneName) : '')}</h1>

                    <hr />

                    <FormattedMessage
                        {...messages.ticketNumber}
                        values={{
                            ref: currentTransaction.titleId,
                        }}
                    />

                    {(currentTransaction.alreadyPaidCharge > 0) && (
                        <div className="already-payed text-primary">
                            <FormattedMessage
                                {...messages.youAlreadyPayed}
                                values={{
                                    price: <Price value={currentTransaction.alreadyPaidCharge} currency={currentTransaction.currencyIso} currencyCentsDigits={currentTransaction.currencyCentsDigits} />,
                                }}
                            />
                        </div>
                    )}

                    <div className="card times">
                        <div className="time time-in">
                            <label><FormattedMessage {...messages.enter}/></label>
                            <div className="day"><FormattedDate value={moment(currentTransaction.entryDateTime).toString()}/></div>
                            <div className="value"><FormattedTime value={moment(currentTransaction.entryDateTime).toString()}/></div>
                        </div>
                        <div className="arrow"/>
                        <div className="time time-out">
                            <label><FormattedMessage {...messages.timePayment}/></label>
                            <div className="day"><FormattedDate value={moment(currentTransaction.createdAt).toString()}/></div>
                            <div className="value"><FormattedTime value={moment(currentTransaction.createdAt).toString()}/></div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 card">
                            <label><FormattedMessage {...messages.parkingTime}/></label>
                            <div className="value">
                                <FormattedMessage
                                    {...messages.parkingTimeFormat}
                                    values={{
                                        hours: Math.floor(moment.duration(moment(currentTransaction.createdAt).diff(moment(currentTransaction.entryDateTime))).asHours()),
                                        minutes: moment.duration(moment(currentTransaction.createdAt).diff(moment(currentTransaction.entryDateTime))).minutes(),
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-12 card">
                            {!currentTransaction.alreadyPaidCharge ? (
                                <label><FormattedMessage {...messages.totalToPay}/></label>
                            ) :
                                <label><FormattedMessage {...messages.leftToPay}/></label>
                            }
                            <div className="value large"><Price value={currentTransaction.totalDueAmount} currency={currentTransaction.currencyIso} currencyCentsDigits={currentTransaction.currencyCentsDigits} /></div>
                        </div>
                    </div>

                    <div className="card ">
                        <label><FormattedMessage {...messages.timeLimit}/></label>
                        <div className="value"><FormattedTime value={moment(currentTransaction.exitDateTime).toString()}/></div>
                    </div>

                    {startError && (
                        <ErrorMessage>
                            <FormattedMessage {...GenericMessages.error} />
                        </ErrorMessage>
                    )}
                    {error ? (
                        <ErrorMessage title={formatMessage(error)} />
                    ) : null}
                    {(currentTransaction.totalDueAmount > 0) ? (
                        <>
                            <label className="checkbox-row">
                                <input
                                    type="checkbox"
                                    onChange={onInvoiceCheckChange}
                                    disabled={transactions.start.loading || isWaitingForPayment || authNmiState.loading}
                                /><span className="check" />
                                <span className="label"><FormattedMessage {...messages.invoiceLabel}/></span>
                            </label>
                            {userWantInvoice && (
                                <div className={'input-row ' + (userEmailError ? 'error' : '')}>
                                    <input
                                        type="text"
                                        placeholder={formatMessage(messages.emailPlaceholder)}
                                        onBlur={checkEmail}
                                        onChange={onEmailChange}
                                        autoCapitalize="none"
                                    />
                                    {userEmailError && (
                                        <div className="error"><FormattedMessage {...messages.emailError}/></div>
                                    )}
                                </div>
                            )}
                            <Button onClick={next} loading={transactions.start.loading || isWaitingForPayment || authNmiState.loading}>
                                <FormattedMessage {...messages.ctaPayment}/>
                            </Button>
                        </>
                    ) : <div className="text-primary highlight"><FormattedMessage {...messages.nothingToPay} /></div>}
                </>
            )}
        <Modal3DSecure isVisible={modal3DSecureVisible} onClose={close3DSecureModal}/>
        </div>
    );

};

const mapStateToProps = (state: MainReducerState) => ({
    themeState: getThemeState(state),
    transactions: state.transactions,
});

export default connect(
    mapStateToProps,
    {
        start: transactionsStart.trigger,
        startReset: transactionsStart.reset,
        authNmiCompleted: authNmiCompletedAction.trigger,
        authNmiFailure: authNmiFailureAction.trigger,
        authNmiError: authNmiErrorAction.trigger,
    },
)(TransactionPage);
