import React, { FC } from 'react';

import { LayoutContextProvider } from '../context/LayoutContext';
import Header from './Header';
import Theme from './Theme';

const MainLayout: FC = ({ children }) => {

    return (
        <LayoutContextProvider>
            <Theme />
            <div id="main-layout">
                <Header />
                <div id="main-content">
                    {children}
                </div>
            </div>
        </LayoutContextProvider>
    );
};

export default MainLayout;
