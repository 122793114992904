import React, { FC, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { MainReducerState } from '../../store/reducers';
import Seo from '../../components/Seo';
import Button from '../../components/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import { useHistory } from 'react-router-dom';
import { getRoute, RoutePathName } from '../../routes';

import {ReactComponent as IconError} from '../../assets/images/icon-error.svg';
import { LayoutContext } from '../../context/LayoutContext';
import useLocalStorage from '../../hooks/localStorage';
import { getThemeState, ThemeState } from '../../store/actions/theme';
import { Transaction } from '../../store/api/apiTypes';

interface PaymentErrorProps {
    themeState: ThemeState;
}

const PaymentError: FC<PaymentErrorProps> = ({
    themeState,
}) => {
    const { formatMessage } = useIntl();
    const history = useHistory();
    const { setHideHeader } = useContext(LayoutContext);
    const [siteId] = useLocalStorage('currentSiteId', undefined);
    const [ticketCode] = useLocalStorage('currentTicketCode', undefined);
    const [securityHash] = useLocalStorage('currentSecurityHash', undefined);
    const [transaction] = useLocalStorage('currentTransaction', undefined);
    const [userInput] = useLocalStorage('titleId', '');

    const currentTransaction: Transaction = (transaction && transaction !== undefined) ? transaction : undefined;

    useEffect(() => {
        setHideHeader(false);
    }, [setHideHeader]);

    const back = () => {
        if (ticketCode && securityHash) {
            history.push(getRoute(RoutePathName.home, {}, {
                s: siteId,
                c: ticketCode,
                h: securityHash,
            }));
        } else if (userInput && siteId) {
            history.push(getRoute(RoutePathName.ticket, {}, {
                s: siteId,
                autoInit: userInput,
            }));
        } else {
            history.push(getRoute(RoutePathName.ticket, {}, {
                s: siteId,
            }));
        }
    };

    return (
        <div id="payment-layout">
            <Seo title={formatMessage(messages.pageTitle)} />
            <h1>{themeState.name + (currentTransaction.zoneName ? (' - ' + currentTransaction.zoneName) : '')}</h1>

            <hr />

            <div className="payment-result confirm">
                <IconError />
                <div className="text-primary">
                    <FormattedMessage {...messages.paymentError} />
                </div>
            </div>

            <p>
                <FormattedMessage {...messages.intro} />
            </p>

            <p>
                <FormattedMessage {...messages.intro2} />
            </p>

            <Button onClick={back}>
                <FormattedMessage {...messages.ctaBack}/>
            </Button>

        </div>
    );

};

const mapStateToProps = (state: MainReducerState) => ({
    themeState: getThemeState(state),
});

export default connect(
    mapStateToProps,
    {
    },
)(PaymentError);
