import { defineMessages } from 'react-intl';

export default defineMessages({
    pageTitle: {
        id: 'ticket.pageTitle',
        defaultMessage: 'Informations de votre ticket',
        description: 'Page title',
    },
    ticketNumber: {
        id: 'ticket.ticketNumber',
        defaultMessage: 'N° ticket : {ref}',
        description: 'Ticket number',
    },
    youAlreadyPayed: {
        id: 'ticket.youAlreadyPayed',
        defaultMessage: 'Vous avez déjà payé : {price}',
        description: 'You already payed',
    },
    enter: {
        id: 'ticket.enter',
        defaultMessage: 'Entrée',
        description: 'Enter',
    },
    timePayment: {
        id: 'ticket.timePayment',
        defaultMessage: 'Heure de paiement',
        description: 'Time of payment',
    },
    parkingTime: {
        id: 'ticket.parkingTime',
        defaultMessage: 'Durée de stationnement',
        description: 'Parking Time',
    },
    parkingTimeFormat: {
        id: 'ticket.parkingTimeFormat',
        defaultMessage: '{hours} h {minutes} min',
        description: 'Date format for the parking Time',
    },
    totalToPay: {
        id: 'ticket.totalToPay',
        defaultMessage: 'Total à payer',
        description: 'Total to pay',
    },
    leftToPay: {
        id: 'ticket.leftToPay',
        defaultMessage: 'Reste à payer',
        description: 'Left to pay',
    },
    timeLimit: {
        id: 'ticket.timeLimit',
        defaultMessage: 'Heure limite pour la sortie',
        description: 'Time limit',
    },
    invoiceLabel: {
        id: 'ticket.invoiceLabel',
        defaultMessage: 'Je souhaite recevoir mon reçu par mail',
        description: 'Invoice checkbox label',
    },
    emailPlaceholder: {
        id: 'ticket.emailPlaceholder',
        defaultMessage: 'Saisissez votre adresse e-mail',
        description: 'Input email placeholder',
    },
    emailError: {
        id: 'ticket.emailError',
        defaultMessage: 'Veuillez saisir une adresse e-mail valide',
        description: 'Input email error message',
    },
    ctaPayment: {
        id: 'ticket.cta.payment',
        defaultMessage: 'Payer votre parking maintenant',
        description: 'CTA Pay Now',
    },
    nothingToPay: {
        id: 'ticket.nothingToPay',
        defaultMessage: 'Vous n\'avez rien à payer',
        description: 'CTA Pay Now',
    },
});
