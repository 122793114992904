import * as React from 'react';
import { useIntl } from 'react-intl';

interface PriceProps {
    value ?: number;
    currency ?: string;
    currencyCentsDigits ?: number;
}

const Price: React.FC<PriceProps> = ({ value, currency, currencyCentsDigits }) => {
    const { locale } = useIntl();

    return (value !== undefined) ? (
        <div className="price-tag">
            {
                Intl.NumberFormat(locale, {
                    style: 'currency',
                    currency: currency?.trim(),
                }).format(value / Math.pow(10, currencyCentsDigits || 2))
            }
        </div>
    ) : null;
};

Price.defaultProps = {
    currency: 'EUR',
    currencyCentsDigits: 2,
};

export default Price;
