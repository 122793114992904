import { defineMessages } from 'react-intl';

export default defineMessages({
    pageTitle: {
        id: 'payment.pageTitle',
        defaultMessage: 'Paiement',
        description: 'Page title',
    },
    totalToPay: {
        id: 'payment.totalToPay',
        defaultMessage: 'Total à payer :',
        description: 'Total to pay',
    },
    selectPaymentMethod: {
        id: 'payment.selectPaymentMethod',
        defaultMessage: 'Choisissez votre moyen de paiement',
        description: 'Select a payment method',
    },
    ctaPayment: {
        id: 'payment.cta.payment',
        defaultMessage: 'Valider le paiement',
        description: 'CTA Validate Payment',
    },
});
