import { Site, Transaction } from './../api/apiTypes';
import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';

import {
    init as initApiCall,
    theme as themeApiCall,
    details as detailsApiCall,
    start as startApiCall,
    TransactionInitPayload,
    TransactionThemePayload,
    TransactionDetailsPayload,
    invoice as invoiceApiCall,
    authNmiCompleted as authNmiCompletedApiCall,
    authNmiFailure as authNmiFailureApiCall,
    authNmiError as authNmiErrorApiCall,
    TransactionAuthNmiCompletedPayload,
    TransactionAuthNmiFailurePayload,
    TransactionAuthNmiErrorPayload,
} from '../api/transactions';

// State

export interface TransactionsState {
    init: RequestState<Transaction | undefined>;
    details: RequestState<Transaction | undefined>;
    authNmiCompleted: RequestState<Transaction | undefined>;
    authNmiFailure: RequestState<Transaction | undefined>;
    authNmiError: RequestState<Transaction | undefined>;
    start: RequestState<Transaction | undefined>;
    theme: RequestState<Site | undefined>;
    invoice: RequestState<string | undefined>;
}

const initialState: TransactionsState = {
    init: {
        data: undefined,
        loading: false,
        success: undefined,
        error: undefined,
    },
    authNmiCompleted: {
        data: undefined,
        loading: false,
        success: undefined,
        error: undefined,
    },
    authNmiFailure: {
        data: undefined,
        loading: false,
        success: undefined,
        error: undefined,
    },
    authNmiError: {
        data: undefined,
        loading: false,
        success: undefined,
        error: undefined,
    },
    start: {
        data: undefined,
        loading: false,
        success: undefined,
        error: undefined,
    },
    details: {
        data: undefined,
        loading: false,
        success: undefined,
        error: undefined,
    },
    theme: {
        data: undefined,
        loading: false,
        success: undefined,
        error: undefined,
    },
    invoice: {
        data: undefined,
        payload: undefined,
        loading: false,
        success: false,
    },
};

// Actions/Reducers

export const init = new EzeeAsyncAction<
    TransactionsState['init'],
    TransactionInitPayload,
    Transaction
>('transactions/init', initialState.init, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.init,
    }),
});

export const details = new EzeeAsyncAction<
    TransactionsState['details'],
    TransactionDetailsPayload,
    Transaction
>('transactions/details', initialState.details, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.details,
    }),
});

export const start = new EzeeAsyncAction<
    TransactionsState['start'],
    Transaction,
    Transaction
>('transactions/start', initialState.start, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
        success: undefined,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.start,
    }),
});

export const theme = new EzeeAsyncAction<
    TransactionsState['theme'],
    TransactionThemePayload,
    Site
>('transactions/theme', initialState.theme, {
    trigger: (state, payload) => ({
        ...state,
        loading: true,
    }),
    success: (state, payload) => ({
        data: payload,
        loading: false,
        success: true,
    }),
    failure: (state, payload) => ({
        ...state,
        loading: false,
        error: payload,
    }),
    reset: (state) => ({
        ...initialState.theme,
    }),
});

export const invoice = new EzeeAsyncAction<
    TransactionsState['invoice'],
    Transaction,
    string
>('transactions/invoice', initialState.invoice, {
    trigger: (state, payload) => ({
        ...state,
        payload,
        loading: true,
        success: undefined,
        error: undefined,
    }),
    success: (state, data) => ({
        ...state,
        data,
        loading: false,
        success: true,
    }),
    failure: (state, data) => ({
        ...state,
        loading: false,
        error: data,
        success: false,
    }),
    reset: (state) => ({
        ...initialState.invoice,
    }),
});

export const authNmiCompleted = new EzeeAsyncAction<
    TransactionsState['authNmiCompleted'],
    TransactionAuthNmiCompletedPayload,
    Transaction
>(
    'offers/authNmiCompleted',
    initialState.authNmiCompleted,
    {
        trigger: (state, payload) => ({
            ...state,
            payload,
            loading: true,
            success: undefined,
            error: undefined,
        }),
        success: (state, data) => ({
            ...state,
            data,
            loading: false,
            success: true,
        }),
        failure: (state, data) => ({
            ...state,
            loading: false,
            error: data,
            success: false,
        }),
        reset: (state) => ({
            ...initialState.authNmiCompleted,
        }),
    },
);

export const authNmiFailure = new EzeeAsyncAction<TransactionsState['authNmiFailure'], TransactionAuthNmiFailurePayload, Transaction>(
    'offers/authNmiFailure',
    initialState.authNmiFailure,
    {
        trigger: (state, payload) => ({
            ...state,
            payload,
            loading: true,
            success: undefined,
            error: undefined,
        }),
        success: (state, data) => ({
            ...state,
            data,
            loading: false,
            success: true,
        }),
        failure: (state, data) => ({
            ...state,
            loading: false,
            error: data,
            success: false,
        }),
        reset: (state) => ({
            ...initialState.authNmiFailure,
        }),
    },
);

export const authNmiError = new EzeeAsyncAction<TransactionsState['authNmiError'], TransactionAuthNmiErrorPayload, Transaction>(
    'offers/authNmiError',
    initialState.authNmiError,
    {
        trigger: (state, payload) => ({
            ...state,
            payload,
            loading: true,
            success: undefined,
            error: undefined,
        }),
        success: (state, data) => ({
            ...state,
            data,
            loading: false,
            success: true,
        }),
        failure: (state, data) => ({
            ...state,
            loading: false,
            error: data,
            success: false,
        }),
        reset: (state) => ({
            ...initialState.authNmiError,
        }),
    },
);

// Reducer

export const transactionsReducer = combineReducers<TransactionsState>({
    init: init.reducer,
    details: details.reducer,
    theme: theme.reducer,
    start: start.reducer,
    invoice: invoice.reducer,
    authNmiCompleted: authNmiCompleted.reducer,
    authNmiFailure: authNmiFailure.reducer,
    authNmiError: authNmiError.reducer,
});

// Saga

export function* transactionsSaga() {
    yield takeLatest(init.type.trigger, simpleAsyncSaga(initApiCall, init));
    yield takeLatest(details.type.trigger, simpleAsyncSaga(detailsApiCall, details));
    yield takeLatest(theme.type.trigger, simpleAsyncSaga(themeApiCall, theme));
    yield takeLatest(start.type.trigger, simpleAsyncSaga(startApiCall, start));
    yield takeLatest(invoice.type.trigger, simpleAsyncSaga(invoiceApiCall, invoice));
    yield takeLatest(authNmiCompleted.type.trigger, simpleAsyncSaga(authNmiCompletedApiCall, authNmiCompleted));
    yield takeLatest(authNmiFailure.type.trigger, simpleAsyncSaga(authNmiFailureApiCall, authNmiFailure));
    yield takeLatest(authNmiError.type.trigger, simpleAsyncSaga(authNmiErrorApiCall, authNmiError));
}

// Store helpers

export const getTransactionsState = (state: MainReducerState) => state.transactions;
export const getAuthNmiCompletedState = (state: MainReducerState) => state.transactions.authNmiCompleted;
