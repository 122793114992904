import React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { setDefaultBreakpoints, BreakpointProvider } from 'react-socks';
import { CookiesProvider } from 'react-cookie';
import IntlProvider from './components/IntlProvider';
import { UniversalCookie } from './store/actions/cookies';
import configureStore from './store/configureStore';
import App from './App';

setDefaultBreakpoints([
    { xs: 0 },
    { sm: 480 },
    { md: 576 },
    { lg: 768 },
    { xl: 992 },
    { xxl: 1200 },
    { xxxl: 1600 },
]);

const store = configureStore();

const render = (Component: React.ComponentType) => {
    return ReactDOM.render(
        (
            <Provider store={store}>
                <CookiesProvider cookies={UniversalCookie}>
                    <BrowserRouter>
                        <IntlProvider>
                            <BreakpointProvider>
                                <Component />
                            </BreakpointProvider>
                        </IntlProvider>
                    </BrowserRouter>
                </CookiesProvider>
            </Provider>
        ),
        document.getElementById('root') as HTMLElement,
    );
};

const init = async () => {
    const checkPolyfills = async () => {
        if (!window.IntersectionObserver) {
            await import('intersection-observer');
        }
        if (!window.URLSearchParams) {
            await import('url-search-params-polyfill');
        }
    };

    await checkPolyfills();

    render(App);
};

init();

if (module.hot) {
    module.hot.accept('./App', () => {
        const NextApp = require('./App').default;
        render(NextApp);
    });
}
