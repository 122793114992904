import React from 'react';
import { IntlProvider as ReactIntlProvider, IntlConfig } from 'react-intl';

import apiClient from '../store/api/_client';
import { hasOwnProp } from '../helpers';
import { Languages } from '../types';
import Loader from './Loader';

interface LangImport {
    [lang: string]: any;
}

interface IntlProviderProps {
    children?: React.ReactNode;
}

interface IntlProviderState {
    locale: Languages;
    messages: IntlConfig['messages'] | null;
}

/**
 * Intl wrapper around the app
 */
export default class IntlProvider extends React.Component<IntlProviderProps, IntlProviderState> {
    constructor(props: IntlProviderProps) {
        super(props);
        this.state = {
            locale: Languages.en,
            messages: null,
        };
        this.setLang();
    }

    public render() {
        const { children } = this.props;
        const { locale, messages } = this.state;

        if (messages === null) {
            return (
                <div id="initial-loader">
                    <Loader loading={true} />
                </div>
            );
        }

        return (
            <ReactIntlProvider
                locale={locale}
                messages={messages}
            >
                {children}
            </ReactIntlProvider>
        );
    }

    private async setLang() {
        const availableLangs = Object.values(Languages);
        const locale = navigator.language;
        let languageCode = locale.split('-')[0] as Languages;

        if (!hasOwnProp(Languages, languageCode)) {
            languageCode = Languages.fr;
        }

        // Intl polyfill
        if (!Intl) {
            await import('intl');

            const intlPolyfillLocaleData = availableLangs.filter((lang) => lang !== Languages.en).reduce<LangImport>((acc, lang) => ({
                ...acc,
                [lang]: () => import(`intl/locale-data/jsonp/${lang}.js`),
            }), {});

            if (hasOwnProp(intlPolyfillLocaleData, languageCode)) {
                await intlPolyfillLocaleData[languageCode]?.();
            }
        }

        const l10nFiles = availableLangs.reduce<LangImport>((acc, lang) => ({
            ...acc,
            [lang]: {
                messages: () => import(`../locale/l10n/${lang}.json`),
            },
        }), {});
        const momentLocales = availableLangs.filter((lang) => lang !== Languages.en).reduce<LangImport>((acc, lang) => ({
            ...acc,
            [lang]: () => import(`moment/locale/${lang}`),
        }), {});

        if (hasOwnProp(momentLocales, languageCode)) {
            await momentLocales[languageCode]?.();
        }

        const messages = await l10nFiles[languageCode].messages();

        apiClient.defaults.headers['x-holirenting-api-language'] = Languages[languageCode];

        this.setState({
            locale: Languages[languageCode],
            messages: {
                ...messages.default,
            },
        });

        document.getElementsByTagName('html')[0].setAttribute('lang', languageCode);
    }
}
