// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/icon-arrow.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "#ticket-layout .already-payed {\n  font-weight: 600;\n  margin: 0.5em 0 1.3em;\n}\n#ticket-layout .card {\n  margin: 1em 0;\n  padding: 1em 0;\n}\n#ticket-layout .card label {\n  font-size: 10px;\n  font-weight: bold;\n  text-transform: uppercase;\n  margin-bottom: 10px;\n  display: block;\n}\n#ticket-layout .card .value {\n  font-size: 24px;\n  color: #FFCA00;\n  line-height: 1em;\n  margin-top: 10px;\n}\n#ticket-layout .card .value.large {\n  font-size: 32px;\n  font-weight: bold;\n}\n#ticket-layout .times {\n  display: flex;\n  flex-flow: row;\n  align-items: center;\n}\n#ticket-layout .times .arrow {\n  content: \" \";\n  display: block;\n  width: 27px;\n  height: 27px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-size: contain;\n  margin: 0 -10px;\n}\n#ticket-layout .times .time {\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n  width: 50%;\n}\n#ticket-layout .times .time .day {\n  font-size: 12px;\n  color: #808080;\n}\n#ticket-layout .row {\n  display: flex;\n  flex-flow: row;\n  justify-content: space-between;\n}\n#ticket-layout .row .col-12 {\n  width: calc(50% - 0.5em);\n  margin: 0;\n}\n", ""]);
// Exports
module.exports = exports;
