import React, { FC, useState, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { MainReducerState } from '../../store/reducers';
import { useHistory } from 'react-router-dom';
import { getRoute, RoutePathName } from '../../routes';
import Loader from '../../components/Loader';
import { LayoutContext } from '../../context/LayoutContext';
import {
    TransactionsState,
    init as transactionsInit,
    theme as transactionsTheme,
} from '../../store/actions/transactions';
import {
    theme, ThemeState,
} from '../../store/actions/theme';
import useLocalStorage from '../../hooks/localStorage';

import logo from '../../assets/images/logo.png';
import { FormattedMessage, useIntl } from 'react-intl';
import GenericMessages from '../../locale/Generic.messages';
import { TicketSearchStatus } from '../../store/api/apiTypes';
import messages from './messages';

interface HomeProps {
    themeState: ThemeState;
    transactions: TransactionsState;
    init: typeof transactionsInit.trigger;
    initReset: typeof transactionsInit.reset;
    getTheme: typeof transactionsTheme.trigger;
    getThemeReset: typeof transactionsTheme.reset;
    setTheme: typeof theme.actions.set;
    resetTheme: typeof theme.actions.reset;
}

const Home: FC<HomeProps> = ({
    themeState,
    transactions,
    init,
    initReset,
    getTheme,
    getThemeReset,
    setTheme,
    resetTheme,
}) => {
    const { formatMessage } = useIntl();
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(true);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [error, setError] = useState<TicketSearchStatus>();
    const { setHideHeader } = useContext(LayoutContext);
    const [, setSiteId] = useLocalStorage('currentSiteId', undefined);
    const [, setTicketCode] = useLocalStorage('currentTicketCode', undefined);
    const [, setSecurityHash] = useLocalStorage('currentSecurityHash', undefined);
    const [, setTransaction] = useLocalStorage('currentTransaction', undefined);

    // ---------------------------------------
    // Query params

    const urlParams = new URLSearchParams(window.location.search);
    const siteQueryParam = urlParams.get('s');
    const codeQueryParam = urlParams.get('c');
    const hashQueryParam = urlParams.get('h');

    // ---------------------------------------
    // On page init

    useEffect(() => {
        setHideHeader(true);
    }, [setHideHeader]);

    useEffect(() => {
        setSiteId(siteQueryParam);
        setTicketCode(codeQueryParam);
        setSecurityHash(hashQueryParam);
        setTransaction(undefined);

        initTheme();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // ---------------------------------------
    // Transaction status

    const initTransaction = () => {
        if (siteQueryParam && codeQueryParam && hashQueryParam) {
            init({
                siteId: siteQueryParam,
                titleStripe: codeQueryParam,
                hash: hashQueryParam,
            });
        } else if (siteQueryParam) {
            history.push(getRoute(RoutePathName.ticket, {}, {s: siteQueryParam}));
        } else {
            setLoading(false);
        }
    };

    useEffect(() => {
        initReset();

        if (transactions.init.success) {
            setTransaction(transactions.init.data);
            history.push(getRoute(RoutePathName.transaction));
        }

        if (transactions.init.error) {
            setLoading(false);
            setError(transactions.init.error.data.error);
        }
    }, [transactions.init.success, transactions.init.error]); // eslint-disable-line react-hooks/exhaustive-deps

    const getErrorMessage = () => {
        switch (error) {
            case TicketSearchStatus.titleNotAllowed: return formatMessage(messages.titleNotAllowed);
            case TicketSearchStatus.payExtInactiveOnZone: return formatMessage(messages.titleNotAllowed);
            case TicketSearchStatus.ticketNotFound: return formatMessage(messages.ticketNotFound);
            case TicketSearchStatus.ticketOutOfPark: return formatMessage(messages.ticketOutOfParking);
            default: return formatMessage(messages.errorNotAvailable);
        }
    };

    // ---------------------------------------
    // Theme

    const initTheme = () => {
        if (siteQueryParam) {
            getTheme({
                siteId: siteQueryParam,
            });
        } else {
            resetTheme();
            setLoading(false);
        }

    };

    useEffect(() => {
        getThemeReset();

        if (transactions.theme.success) {

            if (transactions.theme.data?.themeColor) {
                setTheme({name: 'color', value: transactions.theme.data.themeColor});
            }
            if (transactions.theme.data?.logoImage) {
                setTheme({name: 'logo', value: transactions.theme.data.logoImage});
            }
            if (transactions.theme.data?.name) {
                setTheme({name: 'name', value: transactions.theme.data.name});
            }

            if (transactions.theme.data?.enabled) {
                initTransaction();
            } else {
                setLoading(false);
                setDisabled(true);
            }
        }

        if (transactions.theme.error) {
            resetTheme();
            setLoading(false);
        }

    }, [transactions.theme.success, transactions.theme.error]); // eslint-disable-line react-hooks/exhaustive-deps

    // ---------------------------------------
    // Render page

    return (
        <div id="loading-layout">
            <Loader loading={loading} />

            {!loading && (
                <div>
                    <img className="logo" src={(themeState?.logo) ? themeState?.logo : logo} alt="logo" />
                    {disabled && (
                        <p className="message"><FormattedMessage {...GenericMessages.siteDisabledMessage} /></p>
                    )}
                    {error && (
                        <p className="message">{getErrorMessage()}</p>
                    )}
                </div>
            )}
        </div>
    );

};

const mapStateToProps = (state: MainReducerState) => ({
    themeState: state.theme,
    transactions: state.transactions,
});

export default connect(
    mapStateToProps,
    {
        getTheme: transactionsTheme.trigger,
        getThemeReset: transactionsTheme.reset,
        init: transactionsInit.trigger,
        initReset: transactionsInit.reset,
        setTheme: theme.actions.set,
        resetTheme: theme.actions.reset,
    },
)(Home);
