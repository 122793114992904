import React, { FC, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { MainReducerState } from '../../store/reducers';
import { LayoutContext } from '../../context/LayoutContext';
import { getThemeState, ThemeState } from '../../store/actions/theme';
import Loader from '../../components/Loader';
import { Transaction, Status } from '../../store/api/apiTypes';
import useLocalStorage from '../../hooks/localStorage';
import {
    TransactionsState,
    details as transactionsDetails,
} from '../../store/actions/transactions';
import { useHistory } from 'react-router-dom';
import { getRoute, RoutePathName } from '../../routes';

interface PaymentCheckProps {
    themeState: ThemeState;
    transactions: TransactionsState;
    getDetails: typeof transactionsDetails.trigger;
    getDetailsReset: typeof transactionsDetails.reset;
}

const PaymentCheck: FC<PaymentCheckProps> = ({
    themeState,
    transactions,
    getDetails,
    getDetailsReset,
}) => {
    const { setHideHeader } = useContext(LayoutContext);
    const history = useHistory();
    const [transaction, setTransaction] = useLocalStorage('currentTransaction', undefined);

    const currentTransaction: Transaction = (transaction && transaction !== undefined) ? transaction : undefined;

    useEffect(() => {
        setHideHeader(false);
    }, [setHideHeader]);

    useEffect(() => {
        if (currentTransaction) {
            getDetails({
                id: currentTransaction.id,
            });
        } else {
            history.push(getRoute(RoutePathName.home));
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getDetailsReset();

        setTransaction(transactions.details.data);

        if (transactions.details.success && transactions.details.data?.paymentStatus) {
            if (transactions.details.data.paymentStatus === Status.Paid) {
                history.push(getRoute(RoutePathName.paymentSuccess));
            } else {
                history.push(getRoute(RoutePathName.paymentError));
            }
        }

        if (transactions.details.error) {
            console.log(transactions.details);
        }
    }, [transactions.details.success, transactions.details.error]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div id="loading-layout">
            <Loader loading={true} />
        </div>
    );

};

const mapStateToProps = (state: MainReducerState) => ({
    themeState: getThemeState(state),
    transactions: state.transactions,
});

export default connect(
    mapStateToProps,
    {
        getDetails: transactionsDetails.trigger,
        getDetailsReset: transactionsDetails.reset,
    },
)(PaymentCheck);
