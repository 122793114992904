import { defineMessages } from 'react-intl';

export default defineMessages({
    next: {
        id: 'next',
        defaultMessage: 'Suivant',
        description: 'Next',
    },
    error: {
        id: 'error',
        defaultMessage: 'Une erreur est survenue, veuillez réessayer utlerieurement.',
        description: 'Generic error message',
    },
    siteDisabledMessage: {
        id: 'siteDisabledMessage',
        defaultMessage: 'Le paiement est indisponible',
        description: 'Error message when a site is disabled',
    },
});
