import { useEffect, useRef, Ref } from 'react';
import { useLocation } from 'react-router-dom';
import { useCurrentBreakpointName } from 'react-socks';

export const usePrevious = <T extends {}>(value: T) => {
    const ref = useRef<T>();

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
};

export const useShareForwardedRef = <T extends {}>(forwardedRef: Ref<T>) => {
    // final ref that will share value with forward ref. this is the one we will attach to components
    const innerRef = useRef<T>(null);

    useEffect(() => {
        // after every render - try to share current ref value with forwarded ref
        if (!forwardedRef) {
            return;
        }
        if (typeof forwardedRef === 'function') {
            forwardedRef(innerRef.current);
            return;
        } else {
            // @ts-ignore
            // by default forwardedRef.current is readonly. Let's ignore it
            forwardedRef.current = innerRef.current;
        }
    });

    return innerRef;
};

export const useQueryParams = () => {
    return new URLSearchParams(useLocation().search);
};

// Tablet is considered mobile here, customize as needed
export const useIsMobile = () => {
    const breakpoint = useCurrentBreakpointName();

    return breakpoint === 'xs' || breakpoint === 'sm' || breakpoint === 'md' || breakpoint === 'lg';
};

export const downloadFile = (filename: string, data: string, type: string = 'text/csv') => {

    const file = new Blob([data], {type});
    const fileUrl = URL.createObjectURL(file);

    const element = document.createElement('a');
    element.href = fileUrl;
    element.download = filename;
    document.body.appendChild(element);
    element.click();
};
