import React, { FC, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { MainReducerState } from '../../store/reducers';
import Seo from '../../components/Seo';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';
import Price from '../../components/Price';
import { useHistory } from 'react-router-dom';
import { getRoute, RoutePathName } from '../../routes';
import { PaylineWidget } from 'react-payline';
import { LayoutContext } from '../../context/LayoutContext';
import useLocalStorage from '../../hooks/localStorage';
import { Transaction } from '../../store/api/apiTypes';
import { getThemeState, ThemeState } from '../../store/actions/theme';

import '../../assets/styles/Payment.less';

interface PaymentProps {
    themeState: ThemeState;
}

const Payment: FC<PaymentProps> = ({
    themeState,
}) => {
    const { formatMessage } = useIntl();
    const history = useHistory();
    const { setHideHeader } = useContext(LayoutContext);
    const [transaction] = useLocalStorage('currentTransaction', undefined);

    const currentTransaction: Transaction = (transaction) ? transaction : undefined;

    const Payline = (window as any).Payline;

    useEffect(() => {
        setHideHeader(false);
    }, [setHideHeader]);

    // ---------------------------------------
    // On page init

    useEffect(() => {
        if (!Payline || !currentTransaction.paymentToken) {
            history.push(getRoute(RoutePathName.transaction));
        }

        if (!currentTransaction) {
            history.push(getRoute(RoutePathName.home));
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // ---------------------------------------
    // Render page

    return (
        <div id="payment-layout">
            <Seo title={formatMessage(messages.pageTitle)} />

            {currentTransaction && (
                <>
                    <h1>{themeState.name + (currentTransaction.zoneName ? (' - ' + currentTransaction.zoneName) : '')}</h1>

                    <hr />

                    <div className="total">
                        <FormattedMessage {...messages.totalToPay} />
                        <strong><Price value={currentTransaction.totalDueAmount} currency={currentTransaction.currencyIso} currencyCentsDigits={currentTransaction.currencyCentsDigits} /></strong>
                    </div>

                    <hr />

                    <div className="payment-method-title">
                        <FormattedMessage {...messages.selectPaymentMethod} />
                    </div>

                    {Payline ? (
                        <PaylineWidget
                            token={currentTransaction.paymentToken}
                            template="column"
                            embeddedRedirectionAllowed={false}
                        />
                    ) : 'Payline not loaded'}
                </>
            )}

        </div>
    );

};

const mapStateToProps = (state: MainReducerState) => ({
    themeState: getThemeState(state),
});

export default connect(
    mapStateToProps,
    {
    },
)(Payment);
