import React, { FC, useContext } from 'react';
import { connect } from 'react-redux';
import { MainReducerState } from '../store/reducers';
import { LayoutContext } from '../context/LayoutContext';
import { getThemeState, ThemeState } from '../store/actions/theme';

import '../assets/styles/Header.less';

import logo from '../assets/images/logo.png';

interface HeaderProps {
    themeState: ThemeState;
}

const Header: FC<HeaderProps> = ({ themeState }) => {

    const { hideHeader } = useContext(LayoutContext);

    return !hideHeader ? (
        <header>
            <img src={(themeState?.logo) ? themeState?.logo : logo} alt="logo" />
        </header>
    ) : null;
};

const mapStateToProps = (state: MainReducerState) => ({
    themeState: getThemeState(state),
});

export default connect(
    mapStateToProps,
    {
    },
)(Header);
