import { defineMessages } from 'react-intl';

export default defineMessages({
    pageTitle: {
        id: 'paymentError.pageTitle',
        defaultMessage: 'Paiement non confirmé',
        description: 'Page title',
    },
    paymentError: {
        id: 'paymentError.paymentError',
        defaultMessage: 'Votre paiement n’est pas confirmé..',
        description: 'Paiement error',
    },
    intro: {
        id: 'paymentError.intro',
        defaultMessage: 'Nous sommes désolé mais votre paiement n’a pas pu être effectué.',
        description: 'Intro',
    },
    intro2: {
        id: 'paymentError.intro2',
        defaultMessage: 'Cliquez sur « Retour au paiement » pour essayer à nouveau ou rendez-vous à une borne dans votre parking.',
        description: 'Intro 2',
    },
    ctaBack: {
        id: 'paymentError.cta.back',
        defaultMessage: 'Retour au paiement',
        description: 'CTA Back',
    },
});
