import { defineMessages } from 'react-intl';

export default defineMessages({
    pageTitle: {
        id: 'paymentSuccess.pageTitle',
        defaultMessage: 'Paiement confirmé',
        description: 'Page title',
    },
    paymentConfirmed: {
        id: 'paymentSuccess.paymentConfirmed',
        defaultMessage: 'Votre paiement est confirmé.',
        description: 'Paiement confirmed',
    },
    intro: {
        id: 'paymentSuccess.intro',
        defaultMessage: 'Nous venons de débiter {price} sur votre carte bancaire.',
        description: 'Intro',
    },
    ctaInvoice: {
        id: 'paymentSuccess.cta.invoice',
        defaultMessage: 'Télécharger le reçu',
        description: 'CTA Download invoice',
    },
    invoiceEmail: {
        id: 'paymentSuccess.invoiceEmail',
        defaultMessage: 'Une copie de votre reçu vous a été envoyé à l’adresse e-mail : {email}',
        description: 'Invoice email message',
    },
    outro1: {
        id: 'paymentSuccess.outro1',
        defaultMessage: 'Vous pouvez vous diriger avec votre véhicule à la sortie du parking jusqu’à {time}.',
        description: 'Outro',
    },
    outro2: {
        id: 'paymentSuccess.outro2',
        defaultMessage: 'Nous vous remercions de votre confiance.',
        description: 'Outro',
    },
});
