import React, { FC } from 'react';
import '../../assets/styles/modal3D.less';
import Button from '../../components/Button';
import { ReactComponent as IconClose } from '../../assets/images/icon-close.svg';

interface Modal3DSecureProps {
    isVisible: boolean;
    onClose: () => void;
}

const Modal3DSecure: FC<Modal3DSecureProps> = ({ isVisible, onClose }) => {

    return (
        <div className={'modal ' + (isVisible ? 'visible' : '')}>

                <Button
                    className="button"
                    type="ghost"
                    onClick={onClose}
                >
                    <IconClose />
                </Button>

            <div id="threeDSMountPoint"/>
        </div>
    );
};

export default Modal3DSecure;
