import React, { FC } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { getRawRoute, RoutePathName } from './routes';
import MainLayout from './components/MainLayout';
import Privacy from './pages/privacy';
import Home from './pages/home';
import Transaction from './pages/transaction';
import Ticket from './pages/ticket';
import Payment from './pages/payment';
import PaymentSuccess from './pages/payment-success';
import PaymentError from './pages/payment-error';

import './assets/styles/App.less';
import PaymentCheck from './pages/payment-check';

const App: FC = () => (
    <>
        <MainLayout>
            <Switch>
                <Route
                    exact
                    path={getRawRoute(RoutePathName.home)}
                >
                    <Home />
                </Route>

                <Route
                    exact
                    path={getRawRoute(RoutePathName.ticket)}
                >
                    <Ticket />
                </Route>

                <Route
                    exact
                    path={getRawRoute(RoutePathName.transaction)}
                >
                    <Transaction />
                </Route>

                <Route
                    exact
                    path={getRawRoute(RoutePathName.payment)}
                >
                    <Payment />
                </Route>

                <Route
                    exact
                    path={getRawRoute(RoutePathName.paymentCheck)}
                >
                    <PaymentCheck />
                </Route>

                <Route
                    exact
                    path={getRawRoute(RoutePathName.paymentSuccess)}
                >
                    <PaymentSuccess />
                </Route>

                <Route
                    exact
                    path={getRawRoute(RoutePathName.paymentError)}
                >
                    <PaymentError />
                </Route>

                <Route
                    exact
                    path={getRawRoute(RoutePathName.privacy)}
                >
                    <Privacy />
                </Route>

                <Route path="*">
                    <Redirect to="/" />
                </Route>
            </Switch>
        </MainLayout>
    </>
);

export default App;
